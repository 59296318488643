import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ESIInclude from '../../../../esi';
import styles from './TabbedFlyout.scss';
import formatTestId from '../../../utils/test-id/format-testid';
import notifyAnalytics from '../notify-analytics';
import { saleOffersCategoryDesktop } from '../offers-category-desktop';

const TabbedFlyout = ({ columns, contentId = '', hierarchy = '' }) => {
  const [activeTab, setActiveTab] = useState(-1);
  const activateTab = index => {
    setActiveTab(index);
  };

  const timeoutRef = useRef(null);

  const delayActivateTab = (index, timeout = 250) => {
    timeoutRef.current = setTimeout(() => activateTab(index), timeout);
  };

  const onMouseLeave = () => {
    clearTimeout(timeoutRef.current);
  };

  const maxTabsToDisplay = 8;
  const tabs = columns.slice(0, maxTabsToDisplay);

  const esiRef = useRef(null);

  useEffect(() => {
    delayActivateTab(0, 150);
  }, []);

  const navigate = name => {
    notifyAnalytics(
      `${hierarchy ? `${hierarchy}|` : ''}${tabs[activeTab].name}|${name}`
    );
  };

  if (!tabs?.length) {
    return <></>;
  }

  return (
    <div className={styles.tabbedFlyout} data-testid="desktop-flyout-menu">
      <div className={styles.tabbedContainer} data-tabs-count={tabs.length}>
        <div className={styles.tabsListContainer}>
          <ul
            className={styles.tabsList}
            data-testid="desktop-flyout-tab-list"
            role="tablist"
            aria-orientation="vertical"
          >
            {tabs.map((tab, index) => (
              <li
                onMouseEnter={() => delayActivateTab(index)}
                onFocus={() => activateTab(index)}
                className={classNames(styles.tab, {
                  [styles.tab__active]: index === activeTab,
                  [styles.tab__first]: index === 0 && activeTab <= 0,
                  [styles.tab__salesOffers]: saleOffersCategoryDesktop(
                    tab.name
                  ),
                })}
                key={tab.name}
                data-testid={`desktop-flyout-tab-${index + 1}`}
              >
                <button
                  role="tab"
                  onMouseOut={onMouseLeave}
                  onBlur={onMouseLeave}
                  aria-selected={index === activeTab}
                  data-testid={formatTestId(tab.name, 'flyout-tab-')}
                >
                  {tab.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div
          className={styles.tabbedContentContainer}
          data-testid="desktop-flyout-tab-content"
        >
          <ul role="tabpanel" className={styles.tabbedContentList}>
            {activeTab >= 0 &&
              tabs[activeTab].child.map(item => {
                const { url, name } = item;
                return (
                  <li key={name}>
                    <a
                      onClick={() => navigate(name)}
                      href={url}
                      data-testid={`${formatTestId(name, 'nav-')}`}
                    >
                      {name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {contentId && (
        <div data-testid="navigation-content" ref={esiRef}>
          <ESIInclude
            src={`/content-renderer/navigation/${contentId}?no-hydration=true&no-failure=true&includePolyfills=false&includeCoreJS=false`}
            id="navigation-content"
          />
        </div>
      )}
    </div>
  );
};

const itemShape = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

TabbedFlyout.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  hierarchy: PropTypes.string,
  contentId: PropTypes.string,
};

export default TabbedFlyout;
