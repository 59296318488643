import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import DesktopMenu from './desktop-menu/DesktopMenu';
import getNavigation from '../../../client/fetch/get-navigation';
import {
  addMediaQueryListener,
  isTabletPortrait,
} from '../../utils/device/identifyDevice';
import SEOLinks from './seo-links/SEOLinks';
import postMonetateImpression from '../../utils/monetate/postMonetateImpression';
import isMonetateGroup from '../../utils/monetate/isMonetateGroup';
import MobileMenu from './mobile-menu/MobileMenu';

export const fetchChildCategories = (queryParams, subset) => ({ id }) =>
  getNavigation({ id, depth: 4, subset, queryParams });

function Navigation({ mobileNavShown }) {
  const {
    categories,
    envVars: { monetate },
    features: { monetateExperiences, displaySEOLinks },
    headerClientConfigQueryParameters,
    columnHighlighted: isColumnHighlightedActive,
    mobileBoostWishlist: isMobileFooterWishlistActive,
  } = useContext(EnvVarsFeaturesContext);
  const [flyoutCategories, setFlyoutCategories] = useState({});

  const isTabbedNavActive = isMonetateGroup(
    { monetateExperiences },
    'tabbed-nav-design'
  );

  const menus = [
    {
      id: 'shop-by-department',
      name: 'Shop by department',
      child: categories.child,
    },
  ];

  const subset = `MOBILE_WEB${
    typeof window !== 'undefined' &&
    // eslint-disable-next-line no-underscore-dangle
    window?.__PRELOADED_STATE__?.mobileWebExperiment === true
      ? '_EXPERIMENT'
      : ''
  },FLYOUT`;

  const fetch = fetchChildCategories(headerClientConfigQueryParameters, subset);

  const fetchFlyoutCategories = async () => {
    const navCategories = await getNavigation({
      subset: 'MOBILE_WEB,FLYOUT',
      queryParams: headerClientConfigQueryParameters,
    });
    setFlyoutCategories(navCategories);
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      isTabletPortrait() &&
      !flyoutCategories?.child
    ) {
      addMediaQueryListener(fetchFlyoutCategories);
    }
  }, []);

  useEffect(() => {
    if (mobileNavShown) {
      postMonetateImpression(monetate, 'mobile-menu-open', monetateExperiences);
      postMonetateImpression(monetate, 'include-params', monetateExperiences);
      postMonetateImpression(
        monetate,
        'insert-categories',
        monetateExperiences
      );
    }
  }, [mobileNavShown]);

  if (mobileNavShown) {
    return (
      <MobileMenu
        menus={menus}
        fetchChildCategories={fetch}
        isMobileFooterWishlistActive={isMobileFooterWishlistActive}
      />
    );
  }

  return (
    <>
      <DesktopMenu
        isTabbedNavActive={isTabbedNavActive}
        isColumnHighlightedActive={isColumnHighlightedActive}
        child={
          flyoutCategories.child ? flyoutCategories.child : categories.child
        }
      />
      {displaySEOLinks && <SEOLinks categories={categories.child} />}
    </>
  );
}

Navigation.propTypes = {
  mobileNavShown: PropTypes.bool.isRequired,
};

export default Navigation;
