import React, { useRef } from 'react';
import { string, bool, func, node } from 'prop-types';
import classNames from 'classnames';
import styles from './TabbedDesktopMenuItem.scss';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';
import offersCategoryDesktop, {
  blackFridayCategory,
} from '../offers-category-desktop';

const TabbedDesktopMenuItem = ({
  label,
  url,
  active,
  onClose,
  onSelect,
  isTouch,
  children = undefined,
}) => {
  const isOffer = offersCategoryDesktop(label);
  const isBlackFriday = blackFridayCategory(label);
  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;

    if (children) {
      e.preventDefault();
      (active ? onClose : onSelect)();
    } else {
      notifyAnalytics(label);
    }
  };

  const handleOnClick = e => {
    if (!active && children) {
      e.preventDefault();
      onSelect();
    } else {
      notifyAnalytics(label);
    }
  };

  const timeoutRef = useRef(null);

  const onDelayedMouseLeave = e => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (
      e.relatedTarget.nodeName !== 'LI' &&
      e.relatedTarget.nodeName !== 'A' &&
      e.relatedTarget.role !== 'tabpanel'
    ) {
      onClose();
    }
  };

  const onMouseEnterHandlers = {
    onMouseEnter: onSelect,
    onKeyDown: handleKeyDown,
  };

  const onMouseLeaveHandlers = {
    onMouseLeave: onDelayedMouseLeave,
  };

  const cn = classNames(styles.desktopMenuItem, {
    [styles.desktopMenuItem__active]: active,
  });

  return (
    // eslint-disable-next-line
    <li
      className={cn}
      data-testid={formatTestId(label, 'nav-')}
      {...(!isTouch && { ...onMouseLeaveHandlers })}
      {...(!isTouch && { ...onMouseEnterHandlers })}
    >
      <a
        className={classNames(styles.desktopMenuItemLink, {
          [styles.offersLabel]: isOffer || isBlackFriday,
        })}
        href={url}
        onClick={handleOnClick}
      >
        {label}
      </a>
      {active && children}
    </li>
  );
};

TabbedDesktopMenuItem.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
  active: bool.isRequired,
  onSelect: func.isRequired,
  onClose: func.isRequired,
  isTouch: bool.isRequired,
  children: node,
};

export default TabbedDesktopMenuItem;
