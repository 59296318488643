import getMonetateExperience from './getMonetateExperience';

export const getMonetateParams = (features, partialExperienceName) =>
  getMonetateExperience(features, partialExperienceName)?.parameters || {};

export const getMonetateParamsValue = (
  features,
  partialExperienceName,
  paramName = ''
) => {
  const parameters =
    getMonetateExperience(features, partialExperienceName)?.parameters || {};

  return parameters?.[paramName] ?? '';
};
