import { notifyNavAnalytics } from './navigation/notify-analytics';

export const skipLinks = [
  {
    name: 'Skip to main content',
    url: '#main',
  },
  {
    name: 'Skip to footer',
    url: '#footer',
  },
];

export const getTopLinks = baseUrl => {
  const links = [
    {
      name: 'About us',
      url: '/customer-services/about-us',
      handler: () => notifyNavAnalytics('pn', 'About us'),
    },
    {
      name: 'John Lewis Brands',
      url: '/content/discover-john-lewis-brands',
      handler: () => notifyNavAnalytics('pn', 'John Lewis Brands'),
    },
    {
      name: 'Brands A-Z',
      url: '/brands',
      handler: () => notifyNavAnalytics('pn', 'Brands A-Z'),
    },
    {
      name: 'Our shops',
      url: '/our-shops',
      handler: () => notifyNavAnalytics('pn', 'Our shops'),
    },
    {
      name: 'Customer services',
      url: '/customer-services',
      handler: () => notifyNavAnalytics('pn', 'Customer services'),
    },
    {
      name: 'Our services',
      url: '/our-services',
      handler: () => notifyNavAnalytics('pn', 'Our services'),
    },
    {
      name: 'Track order',
      url: `${baseUrl || ''}/track-order`,
      handler: () => notifyNavAnalytics('pn', 'Track order'),
    },
    {
      name: 'My John Lewis',
      url: '/our-services/my-john-lewis',
      handler: () => notifyNavAnalytics('pn', 'My John Lewis'),
    },
    {
      name: 'Partnership Credit Card',
      url:
        'https://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50|http://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50',
      external: true,
      handler: () => notifyNavAnalytics('pn', 'Partnership Credit Card'),
    },
    {
      name: 'Insurance',
      url: 'https://www.johnlewisfinance.com/insurance.html?track=JLj0008',
      external: true,
      handler: () => notifyNavAnalytics('pn', 'Insurance'),
    },
  ];

  return links;
};

export const getTopLinksWithMenu = baseUrl => {
  const links = [
    {},
    {},
    {},
    {},
    {},
    {
      name: 'My John Lewis',
      url: '/our-services/my-john-lewis',
    },
    {
      name: 'Ways to shop',
      menuLinks: [
        {
          name: 'Services and experiences',
          url: '/our-services',
        },
        {
          name: 'Gift cards',
          url: '/customer-services/prices-and-payment/gift-cards',
        },
        {
          name: 'Our app',
          url: '/customer-services/shopping-with-us/our-apps',
        },
        {
          name: 'Brands A-Z',
          url: '/brands',
        },
      ],
    },
    {
      name: 'Help',
      menuLinks: [
        {
          name: 'Customer services',
          url: '/customer-services',
        },
        {
          name: 'Track your order',
          url: `${baseUrl || ''}/track-order`,
        },
        {
          name: 'Returns and refunds',
          url: '/customer-services/returns',
        },
        {
          name: 'Delivery and collection',
          url: '/customer-services/delivery-information',
        },
        {
          name: 'Product support',
          url: 'https://productsupport.johnlewis.com',
        },
        {
          name: 'Contact us',
          url: '/customer-services/contact-us',
        },
      ],
    },
    {
      name: 'John Lewis Money',
      menuLinks: [
        {
          name: 'Partnership Credit Card',
          url:
            'https://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50|http://www.johnlewisfinance.com/credit-cards/partnership-card.html?sourcecode=3521500AAJLPDOM10800&utm_source=www.johnlewis.com&utm_medium=referral&utm_campaign=PC0072&track=jli:JLPC0072|prenavigation|xc50',
          external: true,
        },
        {
          name: 'Home insurance',
          url: 'https://www.johnlewisfinance.com/insurance/home-insurance.html',
          external: true,
        },
        {
          name: 'Car insurance',
          url: 'https://www.johnlewisfinance.com/insurance/car-insurance.html',
          external: true,
        },
        {
          name: 'Pet insurance',
          url: 'https://www.johnlewisfinance.com/insurance/pet-insurance.html',
          external: true,
        },
        {
          name: 'Travel money',
          url: 'https://www.johnlewisfinance.com/currency.html',
          external: true,
        },
        {
          name: 'International payments',
          url: 'https://www.johnlewisfinance.com/money-transfer.html',
          external: true,
        },
        {
          name: 'Loans',
          url: 'https://www.johnlewisfinance.com/personal-loans.html',
          external: true,
        },
      ],
    },
    {
      name: 'Our stores',
      url: '/our-shops',
      menuLinks: [],
    },
  ];

  return links;
};

export const getAccountLinks = (baseUrl, myAccountUrl) => [
  {
    name: 'My account',
    url: myAccountUrl || '/my-account',
  },
  {
    name: 'Online orders',
    url: `${baseUrl || ''}/orders`,
  },
  {
    name: 'My John Lewis rewards',
    url: `${baseUrl || ''}/rewards`,
  },
  {
    name: 'My reviews',
    url: `${baseUrl || ''}/my-reviews`,
  },
  {
    name: 'Wish list',
    url: `${baseUrl || ''}/wish-list`,
  },
  {
    name: 'Appointments',
    url: '/appointments/manage',
  },
  {
    name: 'Sign out',
    url: '/sign-out',
  },
];

export const FEEDBACK_LINK =
  'https://edigitalsurvey.com/survey/enter/s/ESV-136225211/data2/mobile/collection_url/';
