import React from 'react';
import ESIInclude from '../../esi';
import HeaderWrapper from '../components/header-wrapper/HeaderWrapper';
import EnvVarsFeaturesContext from './EnvVarsFeaturesContext';
import { UserContextProvider } from '../scaffold/UserContext';

class HeaderApp extends React.Component {
  constructor(props) {
    super();
    const {
      envVars = {},
      features = {},
      categories = {},
      headerClientConfigQueryParameters = {},
      query = {},
      notificationBannerFolder = '',
      preNavRedesign = '',
      fallbackMenu = '',
      columnHighlighted = '',
      mobileBoostWishlist = '',
    } = props.initialState;
    this.envVars = envVars;
    this.features = features;
    this.categories = categories;
    this.headerClientConfigQueryParameters = headerClientConfigQueryParameters;
    this.query = query;
    this.notificationBannerFolder = notificationBannerFolder;
    this.preNavRedesign = preNavRedesign;
    this.fallbackMenu = fallbackMenu;
    this.columnHighlighted = columnHighlighted;
    this.mobileBoostWishlist = mobileBoostWishlist;
  }

  render() {
    return (
      <div>
        <ESIInclude
          id="experimentation-esi"
          src="/esi/experimentation/page-load-experiments"
          data-testid="experimentation-esi"
        />
        <UserContextProvider>
          <EnvVarsFeaturesContext.Provider
            value={{
              envVars: this.envVars,
              features: this.features,
              categories: this.categories,
              headerClientConfigQueryParameters: this
                .headerClientConfigQueryParameters,
              query: this.query,
              preNavRedesign: this.preNavRedesign,
              fallbackMenu: this.fallbackMenu,
              columnHighlighted: this.columnHighlighted,
              mobileBoostWishlist: this.mobileBoostWishlist,
            }}
          >
            <HeaderWrapper features={this.features} />
            {this.notificationBannerFolder ? (
              <ESIInclude
                id="header-notification-banner"
                src={`/${this.envVars.bannerPath}/${this.notificationBannerFolder}/index.html`}
                data-testid="header-notification-banner"
              />
            ) : (
              ''
            )}
          </EnvVarsFeaturesContext.Provider>
        </UserContextProvider>
      </div>
    );
  }
}

HeaderApp.displayName = 'HeaderApp';

export default HeaderApp;
