import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import {
  isClient,
  selectInjectedHtml,
  fetchFragment,
  generateWrapperId,
  logger,
} from './utils/utils';
import { ClientESI } from './ClientEsi';

const ESIInclude = ({ src, id }) => {
  const wrapperId = generateWrapperId({ src, id });
  const srcRef = useRef(src);
  const html = isClient() ? selectInjectedHtml(wrapperId, id) : null;
  const [htmlContent, setHtmlContent] = useState(html);
  const [isHydration, setIsHydration] = useState(isClient());

  useEffect(() => {
    if (!htmlContent || src !== srcRef.current) {
      logger(`jl-esi-component: fetching new fragment`, {
        src,
        old_src: srcRef.current,
      });
      fetchFragment(src).then(fragment => {
        srcRef.current = src;
        setHtmlContent(fragment);
        setIsHydration(false);
      });
    }
  }, [src]);

  // During the initial hydration render on the client we just want to
  // restore the markup provided by the server generated response
  if (htmlContent && isHydration) {
    logger('jl-esi-component: client render (no prop changes)', { src, id });
    return (
      <div
        id={wrapperId}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        suppressHydrationWarning
      />
    );
  }

  // When re-rendering on the client as a result of a change to the 'src'
  // prop we need to use the ClientESI (see notes in the component for details)
  if (htmlContent) {
    logger('jl-esi-component: client render (with prop changes)', { src, id });
    return <ClientESI wrapperId={wrapperId} html={htmlContent} />;
  }

  // when on the server we need to render an <esi:include /> tag
  logger('jl-esi-component: server render (should not see this)', { src, id });
  return (
    <div
      id={wrapperId}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `<esi:include id="${id}" ${src ? ` src="${src}"` : ''} />`,
      }}
    />
  );
};

ESIInclude.propTypes = {
  src: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default memo(ESIInclude);
