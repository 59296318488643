import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MyJl24PxFilled from 'jl-design-system/elements/icons-jb/MyJl24PxFilled';
import MobilePhone24PxOutlined from 'jl-design-system/elements/icons-jb/MobilePhone24PxOutlined';
import Feedback24PxOutlined from 'jl-design-system/elements/icons-jb/Feedback24PxOutlined';
import UserProfileIcon from 'jl-design-system/elements/icons-jb/Profile24PxOutlined';
import WishlistIcon from 'jl-design-system/elements/icons-jb/Wishlist24PxOutlined';
import styles from './MobileMenu.scss';
import SubMenu from './SubMenu';
import filterMenuData from '../../../utils/menu/filterMenuData';
import { brandsData, servicesData, helpData } from './tabbedMenuData';
import { FEEDBACK_LINK } from '../../links';
import { UserContext } from '../../../scaffold/UserContext';
import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';

const InspirationLink = {
  name: 'Inspiration',
  url: '/content',
  id: '10139580012',
  includedInMenus: ['MOBILE_WEB'],
};

const MobileMenu = ({
  menus: initialMenus,
  fetchChildCategories,
  isMobileFooterWishlistActive = false,
}) => {
  const rootMenuName = initialMenus[0]?.name || '';

  const initialMenusFiltered = filterMenuData(initialMenus[0]?.child, [
    'MOBILE_WEB',
    'MOBILE_WEB_EXPERIMENT',
  ]);

  const initialRootMenuItems = [
    ...(initialMenusFiltered || []),
    InspirationLink,
  ];

  // pull brands from initial menu data
  const brandsMenuItems = initialRootMenuItems.find(
    item => item.name === 'Brands'
  );

  // pull services from initial menu data
  const servicesMenuItems = initialRootMenuItems.find(
    item => item.name === 'Services'
  );

  const [rootMenuItems, setRootMenuItems] = useState(initialRootMenuItems);

  const top = useRef();
  const [activeLevel, setActiveLevel] = useState(0);

  const scrollToTop = () => {
    if (top.current && top.current.scrollBy) {
      top.current.scrollBy(0, -2000);
    }
  };

  const [activeTab, setActiveTab] = useState('Shop');

  const {
    userState: {
      user: { signedIn, guestWishList },
    },
  } = useContext(UserContext);
  const {
    envVars: { accountBaseUrl },
  } = useContext(EnvVarsFeaturesContext);

  const accountText = signedIn ? 'My account' : 'Sign in';

  const wishListUrl =
    guestWishList && !signedIn
      ? `/wish-list/guest`
      : `${accountBaseUrl}/wish-list`;

  useEffect(() => {
    let updatedSubMenuData = null;
    if (activeTab === 'Brands') {
      if (brandsMenuItems) {
        updatedSubMenuData = brandsMenuItems?.child;
      } else {
        updatedSubMenuData = brandsData?.child;
      }
    }
    if (activeTab === 'Services') {
      if (servicesMenuItems) {
        updatedSubMenuData = servicesMenuItems?.child;
      } else {
        updatedSubMenuData = servicesData?.child;
      }
    }
    if (activeTab === 'Help') {
      updatedSubMenuData = helpData?.child;
    }

    if (activeTab !== 'Shop') {
      setRootMenuItems(updatedSubMenuData);
    } else {
      // dont show brands or services in shop menu
      setRootMenuItems(
        initialRootMenuItems.filter(
          item => item.name !== 'Brands' && item.name !== 'Services'
        )
      );
    }
  }, [activeTab]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchSubMenuData = async (menuItemId, menuIndex) => {
    if (rootMenuItems[menuIndex].isCached) {
      return;
    }

    try {
      const fetchedData = await fetchChildCategories({
        id: menuItemId,
      });

      if (mounted.current === false) {
        return;
      }

      if (!fetchedData || fetchedData.error) {
        throw new Error('Fetch failed');
      }

      setRootMenuItems(existingRootMenuItems =>
        existingRootMenuItems.map((existing, i) =>
          i === menuIndex
            ? {
                ...existing,
                child: fetchedData.child || [],
                isCached: true,
              }
            : existing
        )
      );
    } catch (e) {
      console.error(`fetch failed for category id: ${menuItemId}`);
    }
  };

  const activeMenuStyles = [
    styles.activeLevelRoot,
    styles.activeLevelFirst,
    styles.activeLevelSecond,
    styles.activeLevelThird,
  ];
  const subMenuClassName = classNames([
    styles.subMenu,
    activeMenuStyles[activeLevel],
  ]);

  return (
    <>
      <nav
        className={styles.mobileMenu}
        ref={top}
        data-testid="mobile-navigation-menu"
      >
        <div
          className={subMenuClassName}
          data-testid="mobile-navigation-container"
        >
          <SubMenu
            level={0}
            setActiveLevel={setActiveLevel}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            subMenuData={{
              name: rootMenuName,
              child: rootMenuItems,
              backLabel: undefined,
            }}
            onItemSelected={(menuItemId, menuIndex) => {
              fetchSubMenuData(menuItemId, menuIndex);
            }}
            scrollToTop={scrollToTop}
          >
            <div
              data-testid="mobile-menu-static-section"
              className={classNames(
                styles.staticSection,
                styles.feedbackMenuBottom
              )}
            >
              {isMobileFooterWishlistActive && (
                <div className={styles.aboveFooter}>
                  <ul>
                    <li className={styles.aboveFooter__link}>
                      <UserProfileIcon />
                      <a
                        href={accountBaseUrl}
                        onClick={() => {
                          dispatchMonetateQEvent('nav.mobile.account-link');
                        }}
                      >
                        {accountText}
                      </a>
                    </li>
                    <li className={styles.aboveFooter__link}>
                      <WishlistIcon />
                      <a
                        href={wishListUrl}
                        onClick={() => {
                          dispatchMonetateQEvent('nav.mobile.wishlist-link');
                        }}
                      >
                        Wish list
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              <div className={styles.footer}>
                <ul>
                  <li className={styles.footer__link}>
                    <MyJl24PxFilled iconState="icon-invert" />
                    <a href="/our-services/my-john-lewis">My John Lewis</a>
                  </li>
                  <li className={styles.footer__link}>
                    <MobilePhone24PxOutlined iconState="icon-invert" />
                    <a href="/customer-services/shopping-with-us/our-apps">
                      Download the John Lewis app
                    </a>
                  </li>
                  <li className={styles.footer__link}>
                    <Feedback24PxOutlined iconState="icon-invert" />
                    <a
                      href={FEEDBACK_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Leave feedback
                    </a>
                  </li>
                  <li
                    className={classNames(
                      styles.footer__link,
                      styles.footer__nku
                    )}
                  >
                    <a href="/content/never-knowingly-undersold">
                      Never Knowingly Undersold
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </SubMenu>
        </div>
      </nav>
    </>
  );
};

const itemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape), null);

MobileMenu.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  fetchChildCategories: PropTypes.func.isRequired,
};

export default MobileMenu;
