import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './MobileMenuItem.scss';
import offersCategory from '../offers-category';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';
import filterMenuData from '../../../utils/menu/filterMenuData';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';

const MobileMenuItem = ({
  label,
  url = '',
  className = '',
  onClick = undefined,
  child = undefined,
  hierarchy: parentHierarchy = [],
  children = undefined,
  isStaticMenu,
  type = '',
  isOffersRemoveViewMoreActive = false,
  isBrandsTabs = false,
}) => {
  const isButton = child?.length > 0;
  const isHeading = type === 'MENUITEM';
  const isBrandHeading = type === 'BRANDMENUITEM';
  const isAnchor = url && !isButton;
  const isOffer = offersCategory(label, 1);
  const isMoneySection =
    isHeading && label.toLowerCase() === 'john lewis money';
  const offersChildren =
    filterMenuData(child, ['MOBILE_WEB', 'MOBILE_WEB_EXPERIMENT'])?.filter(
      item => !!item.url
    ) || [];
  const hierarchy = [...parentHierarchy, label];
  const sendAnalytics = (tail = [], menuName = '') => {
    notifyAnalytics([...hierarchy, ...tail].join('|'));
    if (isStaticMenu && menuName) {
      dispatchMonetateQEvent(
        `mobile-menu-click.${menuName.replace(/ /g, '-').toLowerCase()}`
      );
    }
  };

  const advanceToChild = e => {
    sendAnalytics();
    onClick(e);
  };

  if (!isAnchor && !isButton && !isHeading && !isBrandHeading) {
    return <></>;
  }

  const getMoreLinkName = moreLabel =>
    `More ${moreLabel
      .toLowerCase()
      .replaceAll(/john lewis/gi, 'John Lewis')
      .replace('our top brands', 'top brands')}`;

  return (
    <>
      <li
        className={classNames(styles.mobileMenuItem, className, {
          [styles.mobileMenuItem__tabbedBrandsMenuGroup]: isBrandsTabs,
        })}
      >
        {isAnchor && (
          <a
            href={url}
            className={classNames(styles.mobileMenuItem__link)}
            onClick={() => sendAnalytics([], label)}
            data-testid={formatTestId(label, 'nav-')}
            data-hierarchy={parentHierarchy.join('|')}
          >
            {label}
          </a>
        )}
        {isButton && !isHeading && !isBrandHeading && (
          <button
            onClick={advanceToChild}
            data-testid={formatTestId(label, 'nav-')}
            className={classNames(
              styles.mobileMenuItem__button,
              styles.mobileMenuItem__chevron,
              styles.mobileMenuItem__button__nonBoldButton,
              {
                [styles.offersLabel]: isOffer,
              }
            )}
          >
            {label}
          </button>
        )}
        {isButton && (isHeading || isBrandHeading) && (
          <div className={styles.buttonHeading}>
            <h4>
              {label === 'top brands' ? 'Our' : ''} {label}
            </h4>
          </div>
        )}
        {isOffer && offersChildren.length > 0 && (
          <ul className={styles.offersSubMenu}>
            {offersChildren.slice(0, 3).map(nestedItem => (
              <li className={styles.mobileMenuItem} key={nestedItem.name}>
                <a
                  className={classNames(styles.mobileMenuItem__link)}
                  onClick={() => sendAnalytics([nestedItem.name])}
                  href={nestedItem.url}
                >
                  {nestedItem.name}
                </a>
              </li>
            ))}
            {!isOffersRemoveViewMoreActive && offersChildren.length > 3 && (
              <li
                className={classNames(
                  styles.mobileMenuItem,
                  styles.mobileMenuItem__viewMoreLink
                )}
              >
                <button
                  className={classNames(styles.mobileMenuItem__button)}
                  onClick={advanceToChild}
                >
                  View more
                </button>
              </li>
            )}
          </ul>
        )}
        {isHeading && (
          <ul className={styles.headingSubMenu}>
            {offersChildren.map(nestedItem => (
              <li className={styles.mobileMenuItem} key={nestedItem.name}>
                <a
                  className={classNames(
                    styles.mobileMenuItem__link,
                    styles.mobileMenuItem__link__tabbedBrandsLink
                  )}
                  onClick={() => sendAnalytics([nestedItem.name])}
                  href={nestedItem.url}
                  target={isMoneySection ? '_blank' : undefined}
                  rel={isMoneySection ? 'noreferrer' : undefined}
                  data-testid={formatTestId(nestedItem.name, 'services-')}
                >
                  {nestedItem.name}
                </a>
              </li>
            ))}
          </ul>
        )}
        {isBrandHeading && (
          <ul className={styles.headingSubMenu}>
            {offersChildren.slice(0, 3).map(nestedItem => (
              <li
                className={classNames(styles.mobileMenuItem)}
                key={nestedItem.name}
              >
                <a
                  className={classNames(
                    styles.mobileMenuItem__link,
                    styles.mobileMenuItem__link__tabbedBrandsLink
                  )}
                  onClick={() => sendAnalytics([nestedItem.name])}
                  href={nestedItem.url}
                  data-testid={formatTestId(nestedItem.name, 'brands-')}
                >
                  {nestedItem.name}
                </a>
              </li>
            ))}
            <li className={classNames(styles.mobileMenuItem)}>
              <button
                onClick={advanceToChild}
                data-testid={formatTestId(label, 'nav-')}
                className={classNames(
                  styles.mobileMenuItem__button,
                  styles.mobileMenuItem__chevron,
                  styles.mobileMenuItem__button__nonBoldButton,
                  styles.mobileMenuItem__chevron__tabbedBrandsChevron
                )}
              >
                {getMoreLinkName(label)}
              </button>
            </li>
          </ul>
        )}
        {children}
      </li>
    </>
  );
};

MobileMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  child: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ),
  hierarchy: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  isOffersRemoveViewMoreActive: PropTypes.bool,
  type: PropTypes.string,
  isBrandsTabs: PropTypes.bool,
};

export default MobileMenuItem;
