const filterMenuData = (menuData = [], valuesToInclude = []) => {
  if (
    !Array.isArray(menuData) ||
    menuData.length === 0 ||
    valuesToInclude.length === 0
  ) {
    return menuData;
  }

  return menuData.filter(item =>
    valuesToInclude.some(value => item?.includedInMenus?.includes(value))
  );
};

export default filterMenuData;
