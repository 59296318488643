import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackButton from '../mobile-menu-item/BackButton';
import MobileMenuItem from '../mobile-menu-item/MobileMenuItem';
import styles from './NestedMenu.scss';
import filterMenuData from '../../../utils/menu/filterMenuData';

const NestedMenuList = ({ name, isFirstItem, child, hierarchy }) => {
  const nestedMenuListDataFiltered = filterMenuData(child, [
    'MOBILE_WEB',
    'MOBILE_WEB_EXPERIMENT',
  ]);

  return (
    <li className={styles.nestedMenuItem}>
      <h4
        className={classNames(styles.mobileMenu__nestedHeading, {
          [styles.mobileMenu__nestedHeading__first]: isFirstItem,
        })}
      >
        {name}
      </h4>
      <ul
        className={classNames(
          styles.mobileMenuList__nested,
          styles.mobileMenuList
        )}
      >
        {nestedMenuListDataFiltered.map(({ id, name: childName, url }) => (
          <MobileMenuItem
            key={id}
            label={childName}
            url={url}
            hierarchy={hierarchy}
          />
        ))}
      </ul>
    </li>
  );
};

const NestedMenu = ({
  data = {},
  hierarchy: parentHierarchy = [],
  onNavigateBack = undefined,
}) => {
  const nestedMenuDataChildFiltered = filterMenuData(data?.child, [
    'MOBILE_WEB',
    'MOBILE_WEB_EXPERIMENT',
  ]);

  return (
    <div
      className={classNames(styles.subMenu, styles.categoryLevelMenu)}
      role="menu"
    >
      {data.name && <BackButton label={data.name} onClick={onNavigateBack} />}
      <ul className={styles.mobileMenuList}>
        {nestedMenuDataChildFiltered?.map(({ id, name, url, child }, index) =>
          child?.length > 0 ? (
            <NestedMenuList
              key={id}
              isFirstItem={index === 0}
              name={name}
              child={child}
              hierarchy={[...parentHierarchy, data.name, name]}
            />
          ) : (
            <MobileMenuItem
              key={id}
              label={name}
              url={url}
              hierarchy={[...parentHierarchy, data.name]}
            />
          )
        )}
      </ul>
    </div>
  );
};

NestedMenu.propTypes = {
  data: PropTypes.shape({
    backLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    child: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        url: PropTypes.string,
      })
    ),
  }),
  onNavigateBack: PropTypes.func,
  hierarchy: PropTypes.arrayOf(PropTypes.string),
};

export default NestedMenu;
