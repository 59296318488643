import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ESIInclude from '../../../../esi';
import styles from './HighlightedFlyoutMenu.scss';
import FlyoutMenuItem from '../flyout-menu-item/FlyoutMenuItem';
import formatTestId from '../../../utils/test-id/format-testid';
import CloseButton from './CloseButton';
import { saleOffersCategoryDesktop } from '../offers-category-desktop';
import filterMenuData from '../../../utils/menu/filterMenuData';
import staticHighlightedColumns from '../../../../assets/content/highlighted-columns.json';

const HighlightedFlyoutMenu = ({
  contentId = '',
  columns,
  hierarchy,
  onClose,
  isTouch = false,
}) => {
  const MAX_COLUMNS = 6;
  const filteredColumns =
    filterMenuData(columns, ['FLYOUT'])
      ?.map(column => ({
        ...column,
        child: filterMenuData(column.child, ['FLYOUT']),
      }))
      ?.filter(x => x.child?.length) || [];

  if (filteredColumns.length === 0) {
    return null;
  }

  const totalDoubleWidthColumns = filteredColumns.filter(
    column => column.child?.length > 11
  ).length;

  const highlightedColumns = staticHighlightedColumns[contentId] || [];
  const hasHighlightedColumns = highlightedColumns.length > 0;

  const columnsToDisplay = filteredColumns.slice(
    0,
    hasHighlightedColumns
      ? MAX_COLUMNS - totalDoubleWidthColumns - 1
      : MAX_COLUMNS - totalDoubleWidthColumns
  );

  return (
    <div
      className={classNames(styles.flyoutMenu, styles.flyoutMenu__equalPadding)}
      data-testid="desktop-flyout-menu"
    >
      <div
        className={styles.flyoutMenuColumns}
        data-testid="desktop-flyout-menu-columns"
      >
        {highlightedColumns.map((column, i) => (
          <div
            key={column.name}
            className={classNames(styles.flyoutMenu__highlighted)}
            data-testid={`desktop-flyout-column-${i + 1}`}
          >
            {column.child &&
              column.child.map((listItems, index) => (
                <div
                  key={listItems.name}
                  className={classNames(styles.flyoutMenuHighlightedRow)}
                  data-testid={`desktop-first-column-${index + 1}`}
                >
                  <ul>
                    {listItems.child.map(link => {
                      const { name, url } = link;
                      return (
                        <FlyoutMenuItem
                          key={name}
                          label={name}
                          url={url}
                          hierarchy={`${hierarchy}|${column.name}`}
                          isTouch={isTouch}
                        />
                      );
                    })}
                  </ul>
                </div>
              ))}
          </div>
        ))}
        {columnsToDisplay.map((column, i) => (
          <div
            key={column.name}
            className={classNames(styles.flyoutMenu__column, {
              [styles.twoCol]: column.child?.length > 11,
            })}
            data-testid={`desktop-flyout-column-${i +
              (hasHighlightedColumns ? 2 : 1)}`}
          >
            <h4
              className={classNames(
                styles.flyoutMenuTitle,
                styles.flyoutMenuTitle__highlighted,
                {
                  [styles.saleOffersLabel]: saleOffersCategoryDesktop(
                    column.name
                  ),
                }
              )}
              data-testid={formatTestId(column.name, 'flyout-heading-')}
            >
              {column.name}
            </h4>
            {column.child && (
              <ul>
                {column.child.map(link => {
                  const { name, url } = link;
                  return (
                    <FlyoutMenuItem
                      key={name}
                      label={name}
                      url={url}
                      hierarchy={`${hierarchy}|${column.name}`}
                      isTouch={isTouch}
                    />
                  );
                })}
              </ul>
            )}
          </div>
        ))}
      </div>
      {contentId && (
        <div
          className={classNames(styles.flyoutMenuBanner)}
          data-testid="navigation-content"
        >
          <ESIInclude
            src={`/content-renderer/navigation/${contentId}?no-hydration=true&no-failure=true&includePolyfills=false&includeCoreJS=false`}
            id="navigation-content"
          />
        </div>
      )}
      {isTouch && <CloseButton onClose={onClose} />}
    </div>
  );
};

const itemShape = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

HighlightedFlyoutMenu.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  hierarchy: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isTouch: PropTypes.bool,
  contentId: PropTypes.string,
};

export default HighlightedFlyoutMenu;
