const separateScriptTags = (htmlString = '') => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = htmlString;
  const elements = wrapper.getElementsByTagName('script');
  const unparsedScripts = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < elements.length; i++) unparsedScripts.push(elements[i]);
  const javaScripts = unparsedScripts.filter(script => {
    // Filter out scripts that are not executed by browsers, i.e.
    // any scripts without the type "application/javascript", "text/javascript", or no type specified
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#JavaScript_types
    switch (script.getAttribute('type')) {
      case 'application/javascript':
      case 'text/javascript':
      case 'module':
      case '':
      case null:
        return true;
      default:
        return false;
    }
  });

  javaScripts.forEach(script => script.parentNode.removeChild(script));

  const newScripts = javaScripts.map(script => {
    const newScript = document.createElement('script');
    if (script.hasAttributes()) {
      for (let i = 0; i < script.attributes.length; i += 1) {
        const { name, value } = script.attributes[i];
        newScript.setAttribute(name, value);
      }
    }
    newScript.innerHTML = script.innerHTML;
    return newScript;
  });

  return {
    scripts: newScripts,
    htmlWithoutScripts: wrapper.innerHTML,
  };
};
export default separateScriptTags;
