import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import separateScriptTags from './utils/separateScriptTags';

/*
 * Due to the script processing model [1], we can’t simply use
 * `document.write`, `.innerHTML`, or even `.cloneNode` to insert the scripts,
 * if we do, they will have the “already started” internal flag set and will be
 * inert, they will appear in the source code but won’t have any effect. This
 * also affects React’s `dangerouslySetInnerHTML`.
 *
 * To work around this, we create _new_ scripts elements with the same
 * attributes and content, like the clone algorithm [2]; and add them to the
 * DOM (which will trigger the script execution).
 *
 * [1]: https://html.spec.whatwg.org/#script-processing-model
 * [2]: https://dom.spec.whatwg.org/#concept-node-clone
 */

// eslint-disable-next-line import/prefer-default-export
export const ClientESI = ({ html, wrapperId }) => {
  const el = useRef(null);
  const { htmlWithoutScripts, scripts } = useMemo(
    () => separateScriptTags(html),
    [html]
  );
  useEffect(() => {
    scripts.forEach(script => el.current.appendChild(script));
    return () => {
      scripts.forEach(
        script =>
          el.current &&
          el.current.contains(script) &&
          el.current.removeChild(script)
      );
    };
  }, [scripts]);

  return (
    <div
      ref={el}
      id={wrapperId}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlWithoutScripts }}
    />
  );
};

ClientESI.propTypes = {
  html: PropTypes.string.isRequired,
  wrapperId: PropTypes.string.isRequired,
};
