import React from 'react';
import InStoreIcon from 'jl-design-system/elements/icons-jb/InStore24PxOutlined';
import styles from './store-finder-icon.scss';
import dispatchMonetateQEvent from '../../utils/monetate/monetateEventDispatcher';

const StoreFinderIcon = () => {
  const handleOnClick = () => {
    dispatchMonetateQEvent('nav.mobile.store-finder');
  };

  return (
    <a
      className={styles.link}
      href="/our-shops"
      data-test="store-finder-anchor"
      data-testid="store-finder-anchor"
      aria-label="Store finder"
      onClick={handleOnClick}
    >
      <InStoreIcon box title="Store Finder" />
      <span className={styles.label} data-testid="store-finder-icon-label">
        Stores
      </span>
    </a>
  );
};

export default StoreFinderIcon;
