import React from 'react';
import MenuItem from '../menu-item/MenuItem';
import styles from './account-menu.scss';

const AccountMenu = ({ isOpen, accountLinks }) =>
  isOpen ? (
    <div className={styles.overlayContainer} data-testid="account-menu">
      <div className={styles.overlay} data-test="overlay">
        <div className={styles.overlayHeader}>
          <h2>My account</h2>
        </div>
        <div className={styles.overlayBody}>
          {accountLinks.map(accountLink => (
            <MenuItem key={accountLink.name} href={accountLink.url}>
              {accountLink.name}
            </MenuItem>
          ))}
        </div>
      </div>
    </div>
  ) : null;

AccountMenu.displayName = 'AccountMenu';

export default AccountMenu;
