import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlyoutMenu from '../flyout-menu/FlyoutMenu';
import TabbedFlyout from '../tabbed-flyout-menu/TabbedFlyout';
import postMonetateImpression from '../../../utils/monetate/postMonetateImpression';
import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import styles from './DesktopMenu.scss';
import TabbedDesktopMenuItem from '../tabbed-desktop-menu-item/TabbedDesktopMenuItem';
import GroupedFlyoutMenu from '../flyout-menu/GroupedFlyoutMenu';
import HighlightedFlyoutMenu from '../flyout-menu/HighlightedFlyoutMenu';
import { isTouchDevice } from '../../../utils/device/identifyDevice';
import DesktopMenuItem from '../desktop-menu-item/DesktopMenuItem';
import filterMenuData from '../../../utils/menu/filterMenuData';

const DesktopMenu = ({
  child,
  isTabbedNavActive,
  isColumnHighlightedActive,
}) => {
  const {
    envVars: { monetate },
    features: { monetateExperiences },
  } = useContext(EnvVarsFeaturesContext);

  const [activeIndex, setActiveIndex] = useState(-1);

  const MAX_NAVIGATION_ITEMS = 12;

  const filteredChild = filterMenuData(child, ['FLYOUT']).slice(
    0,
    MAX_NAVIGATION_ITEMS
  );

  const onSelect = (index, label) => () => {
    setActiveIndex(index);

    const monetateTags = [
      { name: 'Home & Garden', tag: 'home-and-garden-ia' },
      { name: 'Furniture & Lights', tag: 'furniture-lights-ia' },
      { name: 'Electricals', tag: 'electricals-ia' },
      { name: 'Women', tag: 'women-ia' },
      { name: 'Men', tag: 'men-ia' },
      { name: 'Beauty', tag: 'beauty-ia' },
      { name: 'Baby & Child', tag: 'baby-child-ia' },
      { name: 'Sport & Leisure', tag: 'sport-leisure-ia' },
    ];

    const { tag = '' } =
      monetateTags.find(monetateTag => monetateTag.name === label) || {};
    if (tag) {
      postMonetateImpression(monetate, tag, monetateExperiences);
    }

    if (activeIndex !== index) {
      postMonetateImpression(monetate, 'include-params', monetateExperiences);
      postMonetateImpression(
        monetate,
        'insert-categories',
        monetateExperiences
      );
    }
  };

  const onClose = () => {
    if (activeIndex >= 0) setActiveIndex(-1);
  };

  const overlayClassName = classNames(styles.desktopMenuOverlay, {
    [styles.desktopMenuOverlay__active]:
      !isTabbedNavActive && activeIndex !== -1,
    [styles.desktopMenuOverlay__tn]: isTabbedNavActive,
    [styles.desktopMenuOverlay__tn__active]:
      isTabbedNavActive && activeIndex !== -1,
  });

  const isTouch = typeof window !== 'undefined' && isTouchDevice();

  return (
    <nav
      className={classNames(styles.desktopMenu, {
        [styles.desktopMenu__tn]: isTabbedNavActive,
        [styles.desktopMenu__tn__active]:
          isTabbedNavActive && activeIndex !== -1,
      })}
      data-testid="navigation-menu"
    >
      <ul
        className={classNames(styles.desktopMenuList, {
          [styles.desktopMenuList__tn]: isTabbedNavActive,
        })}
        data-testid="navigation-menu-list"
      >
        {filteredChild.map((item, index) => {
          const { contentId, name, url, child: childItems, grouped } = item;
          const onSelectIndex = onSelect(index, name);
          const active = activeIndex === index;
          let Flyout;
          let MenuItem;
          if (isTabbedNavActive) {
            Flyout = TabbedFlyout;
            MenuItem = TabbedDesktopMenuItem;
          } else if (grouped) {
            Flyout = GroupedFlyoutMenu;
            MenuItem = DesktopMenuItem;
          } else if (isColumnHighlightedActive) {
            Flyout = HighlightedFlyoutMenu;
            MenuItem = DesktopMenuItem;
          } else {
            Flyout = FlyoutMenu;
            MenuItem = DesktopMenuItem;
          }
          return (
            <MenuItem
              key={name}
              label={name}
              url={url}
              active={active}
              onClose={onClose}
              onSelect={onSelectIndex}
              isTouch={isTouch}
            >
              {childItems?.length > 0 && (
                <Flyout
                  contentId={contentId}
                  columns={childItems}
                  hierarchy={name}
                  onClose={onClose}
                  isTouch={isTouch}
                />
              )}
            </MenuItem>
          );
        })}
      </ul>
      <div className={overlayClassName} data-testid="desktop-menu-overlay" />
    </nav>
  );
};

const itemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

DesktopMenu.propTypes = {
  child: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
};

export default DesktopMenu;
