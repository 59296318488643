import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import HeaderScaffold from '../app/scaffold/Header';
import getInitialData from './getInitialData';
import { isDesktop } from '../app/utils/device/identifyDevice';
import { isPreview } from '../lib/url/timestamp';
/* eslint-disable no-underscore-dangle */

const loadInitialState = async () => {
  try {
    const { host, search } = window.location;
    const params = new URLSearchParams(
      isPreview(host) && search.length ? search : ''
    );

    const isMWE = window?.__PRELOADED_STATE__?.mobileWebExperiment === true;

    const initialState = isMWE
      ? await getInitialData(isDesktop(), params, isMWE)
      : await getInitialData(isDesktop(), params);
    const headerAppEl = document.getElementById('root');

    if (headerAppEl) {
      const preloadedState = {
        ...initialState,
        ...window.__PRELOADED_STATE__,
      };

      hydrateRoot(
        headerAppEl,
        <HeaderScaffold initialState={preloadedState} />
      );
      // eslint-disable-next-line no-underscore-dangle
      window.__PRELOADED_STATE__ = initialState;
      if (isMWE) {
        window.__PRELOADED_STATE__.mobileWebExperiment = isMWE;
      }
    }
  } catch (err) {
    console.error(`Error hydrating application ${err}`);
  }
};

document.addEventListener('DOMContentLoaded', loadInitialState);
