import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FlyoutMenuItem.scss';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';

const FlyoutMenuItem = ({ label, url, hierarchy, isGroupedActive }) => {
  const navigate = () => {
    notifyAnalytics(`${hierarchy}|${label}`);
  };

  const handleKeyDown = e => {
    e.stopPropagation();
  };

  return (
    <li
      className={classNames(styles.flyoutMenuItem, {
        [styles.flyoutMenuItem__grouped]: isGroupedActive,
      })}
    >
      <a
        href={url}
        onClick={navigate}
        onKeyDown={handleKeyDown}
        data-testid={`${formatTestId(label, 'nav-')}`}
      >
        {label}
      </a>
    </li>
  );
};

FlyoutMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  hierarchy: PropTypes.string.isRequired,
};

export default FlyoutMenuItem;
