import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './MobileMenuItem.scss';
import formatTestId from '../../../utils/test-id/format-testid';

const BackButton = ({ label, onClick }) => (
  <div
    className={classNames(
      styles.mobileMenuItem,
      styles.mobileMenuItem__fullWidth,
      styles.mobileMenuItem__chevronLeft
    )}
  >
    <div
      className={classNames(
        styles.mobileMenuItem__button,
        styles.mobileMenuItem__button__customBackButton
      )}
      data-testid="nav-back-button"
    >
      <button
        data-testid={formatTestId(label, 'nav-heading-')}
        className="small-back-button"
        onClick={onClick}
      />
      {label}
    </div>
  </div>
);

BackButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
