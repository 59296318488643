const status = res =>
  res.status >= 200 && res.status < 300
    ? Promise.resolve(res)
    : Promise.reject(new Error(res.statusText));

const text = res => res.text();

export async function fetchFragment(src) {
  try {
    return await fetch(src)
      .then(status)
      .then(text);
  } catch (error) {
    return null;
  }
}

export const isClient = () =>
  Boolean(typeof window !== 'undefined' && window.document);

export const selectInjectedHtml = (wrapperId, esiId) => {
  if (wrapperId) {
    const wrapperEl = document.getElementById(wrapperId);
    if (wrapperEl) {
      const esiEl = wrapperEl.querySelector(`#${esiId}`);
      return esiEl ? null : wrapperEl.innerHTML;
    }
  }
  return null;
};

export const generateWrapperId = ({ id, src }) => {
  // Use the src prop to generate an id attribute for the wrapping element
  const regex = /\/?([\w-/]*)/;
  let wrapperId = '';
  if (src) {
    const assemblySource = src.match(regex);
    wrapperId = `assembly-${assemblySource[1]}-${id}`
      .replace(/\//g, '-') // change / to -
      .replace(/-+/g, '-') // remove duplicate -
      .toLowerCase();
  }

  return wrapperId;
};

export const getDisplayName = Component =>
  Component.displayName || Component.name || 'Component';

export const logger = (...args) => {
  if (!isClient()) {
    return;
  }

  if (localStorage.getItem('jl-esi-component-debug') === 'true') {
    console.log(...args);
  }
};
