import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackButton from '../mobile-menu-item/BackButton';
import MobileMenuItem from '../mobile-menu-item/MobileMenuItem';
import styles from './MobileMenu.scss';
import NestedMenu from './NestedMenu';
import postMonetateImpression from '../../../utils/monetate/postMonetateImpression';
import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import formatTestId from '../../../utils/test-id/format-testid';
import isMonetateGroup from '../../../utils/monetate/isMonetateGroup';
import { getMonetateParamsValue } from '../../../utils/monetate/getMonetateParams';
import filterMenuData from '../../../utils/menu/filterMenuData';
import StoreFinderButton from '../mobile-menu-item/StoreFinderButton';
import MobileMenuTabs from './MobileMenuTabs';

const SubMenu = ({
  setActiveLevel,
  setActiveTab,
  activeTab,
  subMenuData,
  onNavigateBack = undefined,
  onItemSelected = undefined,
  scrollToTop = undefined,
  hierarchy: parentHierarchy = [],
  level,
  children = undefined,
}) => {
  const {
    envVars: { monetate },
    features,
  } = useContext(EnvVarsFeaturesContext);
  const { monetateExperiences } = features;

  const subMenuDataFiltered = {
    ...subMenuData,
    child: filterMenuData(subMenuData?.child, [
      'MOBILE_WEB',
      'MOBILE_WEB_EXPERIMENT',
    ]),
  };

  const [activeIndex, updateActiveIndex] = useState(-1);
  const hierarchy =
    level > 0 ? [...parentHierarchy, subMenuDataFiltered.name] : [];

  const childNavigateBack = () => {
    scrollToTop();
    setActiveLevel(level);
    updateActiveIndex(-1);
  };

  const cn = classNames(styles.subMenu, {
    [styles.categoryLevelMenu]: level > 0,
  });

  const handleMobileMenuItemClick =
    (index, { id, name }) =>
    () => {
      scrollToTop();
      setActiveLevel(level + 1);
      updateActiveIndex(index);
      if (typeof onItemSelected === 'function') {
        onItemSelected(id, index);
      }
      const monetateTags = [
        { name: 'Home & Garden', tag: 'home-and-garden-ia' },
        { name: 'Furniture & Lights', tag: 'furniture-lights-ia' },
        { name: 'Electricals', tag: 'electricals-ia' },
        { name: 'Women', tag: 'women-ia' },
        { name: 'Men', tag: 'men-ia' },
        { name: 'Beauty', tag: 'beauty-ia' },
        { name: 'Baby & Child', tag: 'baby-child-ia' },
        { name: 'Sport & Leisure', tag: 'sport-leisure-ia' },
      ];

      const { tag = '' } =
        monetateTags.find(monetateTag => monetateTag.name === name) || {};
      if (tag) {
        postMonetateImpression(monetate, tag, monetateExperiences);
      }
      if (level === 1) {
        const subMenuChoice = formatTestId(
          `${subMenuDataFiltered.name} ${name}`
        ); // Home & Garden Bedding -> home-&-garden-bedding
        const experienceParams = getMonetateParamsValue(
          features,
          'mi-L2',
          'categories'
        );
        if (experienceParams.includes(subMenuChoice)) {
          postMonetateImpression(monetate, 'mi-L2', monetateExperiences);
        }
      }
    };

  const isOffersRemoveViewMoreActive = isMonetateGroup(
    { monetateExperiences },
    'offers-remove-view-more'
  );

  const isStoreFinderMobileActiveB = isMonetateGroup(
    { monetateExperiences },
    'store-finder-mobile',
    'Experiment-B'
  );

  const handleTabChange = tab => {
    scrollToTop();
    setActiveTab(tab);
  };

  return (
    <>
      <div
        className={cn}
        role="menu"
        data-testid={formatTestId(subMenuDataFiltered.name, 'submenu-')}
      >
        {subMenuDataFiltered.backLabel && (
          <BackButton
            label={subMenuDataFiltered.name}
            onClick={onNavigateBack}
          />
        )}
        {isStoreFinderMobileActiveB && level === 0 && <StoreFinderButton />}

        {!subMenuDataFiltered.backLabel && (
          <MobileMenuTabs onActiveTabChange={handleTabChange} />
        )}

        {activeTab === 'Brands' && (
          <div className={styles.brandsMenuButton}>
            <a
              href="https://www.johnlewis.com/brands"
              data-testid="brands-explore-all"
            >
              Explore all our brands
            </a>
          </div>
        )}
        {activeTab === 'Services' && (
          <div className={styles.brandsMenuButton}>
            <a
              href="https://www.johnlewis.com/our-services"
              data-testid="services-explore-all"
            >
              Explore all services and experiences
            </a>
          </div>
        )}

        <ul className={styles.mobileMenuList}>
          {subMenuDataFiltered?.child?.map((menuItem, index) => {
            const { id, name, url, child, type, headingName } = menuItem;
            const handleMobileMenuItemClickIndex = handleMobileMenuItemClick(
              index,
              menuItem
            );
            return (
              <MobileMenuItem
                key={id}
                label={headingName || name}
                url={url}
                onClick={handleMobileMenuItemClickIndex}
                child={child}
                type={type}
                hierarchy={hierarchy}
                level={level}
                isOffersRemoveViewMoreActive={isOffersRemoveViewMoreActive}
              >
                {activeIndex === index && (
                  <SubMenuChild
                    {...{
                      setActiveLevel,
                      activeIndex,
                      subMenuDataFiltered,
                      level,
                      type,
                      scrollToTop,
                      hierarchy,
                      childNavigateBack,
                    }}
                  />
                )}
              </MobileMenuItem>
            );
          })}
        </ul>
        {children}
      </div>
    </>
  );
};

function SubMenuChild({
  activeIndex,
  subMenuDataFiltered,
  level,
  scrollToTop,
  hierarchy,
  childNavigateBack,
  setActiveLevel,
}) {
  const childData = {
    backLabel: subMenuDataFiltered.name,
    ...subMenuDataFiltered.child[activeIndex],
  };

  return level === 2 ? (
    <NestedMenu
      data={childData}
      onNavigateBack={childNavigateBack}
      hierarchy={hierarchy}
    />
  ) : (
    <SubMenu
      setActiveLevel={setActiveLevel}
      level={level + 1}
      subMenuData={childData}
      scrollToTop={scrollToTop}
      onNavigateBack={childNavigateBack}
      hierarchy={hierarchy}
    />
  );
}

const itemShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

SubMenu.propTypes = {
  subMenuData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    backLabel: PropTypes.string,
    child: PropTypes.arrayOf(PropTypes.shape(itemShape)),
  }).isRequired,
  onNavigateBack: PropTypes.func,
  onItemSelected: PropTypes.func,
  scrollToTop: PropTypes.func,
  hierarchy: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export default SubMenu;
