import React from 'react';
import classNames from 'classnames';
import MenuIconOpen from 'jl-design-system/elements/icons-jb/Close24PxOutlined';
import MenuIconClosed from 'jl-design-system/elements/icons-jb/Menu24PxOutlined';

// eslint-disable-next-line import/no-named-as-default
import AccountLinks from '../account-links/AccountLinks';
// eslint-disable-next-line import/no-named-as-default
import Basket from '../basket/Basket';
import StoreFinderIcon from '../store-finder-icon/StoreFinderIcon';

import styles from './user-tools.scss';

function UserTools({
  isMenuOpen,
  onMenuButtonClick,
  isTabbedNavActive,
  isTypeaheadOpen,
  isFallbackMenuTestActive,
  isMobileBoostWishlistActive,
}) {
  const menuButtonChildren = (
    <>
      <span className={styles.visuallyHidden}>
        {isMenuOpen ? 'Close' : 'Menu'}
      </span>

      {isMenuOpen ? (
        <MenuIconOpen
          data-testid="nav-menu-close-icon"
          box
          title="Close Menu"
        />
      ) : (
        <MenuIconClosed data-testid="nav-menu-icon" box title="Open Menu" />
      )}

      <span
        className={classNames(styles.navListItemLabel, {
          [styles.navListLabel__tn]: isTabbedNavActive,
        })}
        data-testid="nav-menu-label"
      >
        {isMenuOpen ? 'Close' : 'Menu'}
      </span>
    </>
  );

  const shouldShowStoreFinder =
    !isMobileBoostWishlistActive || (isMobileBoostWishlistActive && isMenuOpen);

  const shouldShowAccountLinks =
    !isMobileBoostWishlistActive ||
    (isMobileBoostWishlistActive && !isMenuOpen);

  const shouldShowBasket =
    !isMobileBoostWishlistActive ||
    (isMobileBoostWishlistActive && !isMenuOpen);

  return (
    <nav
      data-testid="user-tools-nav"
      className={classNames(styles.nav, {
        [styles.nav__active]: isTypeaheadOpen,
        [styles.nav__tn]: isTabbedNavActive,
      })}
    >
      <ul className={classNames(styles.navList)}>
        {shouldShowStoreFinder && (
          <li
            className={classNames(styles.navListItem, styles.storeFinder, {
              [styles.storeFinder__mobile]: isMobileBoostWishlistActive,
            })}
          >
            <StoreFinderIcon />
          </li>
        )}
        {shouldShowAccountLinks && (
          <li
            className={classNames(styles.navListItem, {
              [styles.navListItem__tn]: isTabbedNavActive,
            })}
          >
            <AccountLinks
              isTabbedNavActive={isTabbedNavActive}
              isMobileBoostWishlistActive={isMobileBoostWishlistActive}
            />
          </li>
        )}
        {shouldShowBasket && (
          <li
            className={classNames(styles.navListItem, {
              [styles.navListItem__tn]: isTabbedNavActive,
            })}
          >
            <Basket isTabbedNavActive={isTabbedNavActive}>
              <span
                className={classNames(styles.navListItemLabel, {
                  [styles.navListLabel__tn]: isTabbedNavActive,
                })}
                data-testid="basket-icon-label"
              >
                Basket
              </span>
            </Basket>
          </li>
        )}
        <li
          className={classNames(styles.navListItem, styles.menu, {
            [styles.menu__tn]: isTabbedNavActive,
          })}
          data-testid="menu-icon"
        >
          {!isFallbackMenuTestActive && (
            <a
              href="/menu"
              className={styles.navListItemLink}
              data-testid="nav-link--menu"
              onClick={onMenuButtonClick}
              role="button"
              aria-expanded={isMenuOpen}
              aria-controls="main-navigation-menu"
            >
              {menuButtonChildren}
            </a>
          )}
          {isFallbackMenuTestActive && (
            <button
              className={styles.navListItemLinkButton}
              data-testid="nav-link-button--menu"
              onClick={onMenuButtonClick}
              aria-expanded={isMenuOpen}
              aria-controls="main-navigation-menu"
            >
              {menuButtonChildren}
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default UserTools;
